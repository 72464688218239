import React from 'react'

import Layout from "../../components/Layout"
import AudioPlayer from '../../components/AudioPlayer' 
import CaseOnePodcast from '../../media/2018-10-22-PODCAST_Diagnosis.mp3'
import CaseOneTranscript from '../../media/2018-10-30-PODCAST_Diagnosis_transcript.pdf'
import CaseTwoPodcast from '../../media/2018-10-22-PODCAST_Medication_Management.mp3'
import CaseTwoTranscript from '../../media/2018-10-30-PODCAST_Medication_Management_transcript.pdf'
import CaseThreePodcast from '../../media/2018-10-22-PODCAST_Health_Maintenance.mp3'
import CaseThreeTranscript from '../../media/2018-10-30-PODCAST_Health_Maintenance_transcript.pdf'

export default class MediaPage extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <div className="aga-container aga-fade-in">
            <h1>Media Library</h1>
            <p>Welcome to <em>Clinical Conversations: IBD</em>, a collection of podcasts that provides health care professionals practical information on implementing evidence-based, guidelines recommended care for patients with inflammatory bowel disease.</p>
            <p>Each <em>Clinical Conversations: IBD</em> podcast features a conversation between internist Dr. Doron Schneider, Chief Patient Safety and Quality Officer for Abington Health, and gastroenterologist Dr. Sunanda V. Kane, Professor of Medicine at the Mayo Clinic.</p>

            <h3 className="center">Episode One: IBD Diagnosis</h3>
            { typeof window !== 'undefined' && AudioPlayer && 
              <AudioPlayer 
                mediaTitle="Episode One"
                mediaSubtitle="IBD Diagnosis"
                src={ CaseOnePodcast } 
              />
            }
            <p className="center">
              Download: <a role="button" href={ CaseOnePodcast } download="Episode-One-IBD-Diagnosis.mp3">Audio File</a> | <a role="button" href={ CaseOneTranscript } download="Episode-One-IBD-Diagnosis-transcript.pdf">PDF Transcript</a>
            </p>

            <h3 className="center">Episode Two: IBD Medication Management</h3>
            { typeof window !== 'undefined' && AudioPlayer && 
              <AudioPlayer 
                mediaTitle="Episode Two"
                mediaSubtitle="IBD Medication Management"
                src={ CaseTwoPodcast } 
              />
            }
            <p className="center">
              Download: <a role="button" href={ CaseTwoPodcast } download="Episode-Two-Medication-Management.mp3">Audio File</a> | <a role="button" href={ CaseTwoTranscript } download="Episode-Two-Medication-Management-transcript.pdf">PDF Transcript</a>
            </p>

            <h3 className="center">Episode Three: IBD Health Maintenance</h3>
            { typeof window !== 'undefined' && AudioPlayer && 
              <AudioPlayer 
                mediaTitle="Episode Three"
                mediaSubtitle="IBD Health Maintenance"
                src={ CaseThreePodcast }   
              />
            }
            <p className="center">
              Download: <a role="button" href={ CaseThreePodcast } download="Episode-Three-IBD-Health-Maintenance.mp3">Audio File</a> | <a role="button" href={ CaseThreeTranscript } download="Episode-Three-IBD-Health-Maintenanc-transcript.pdf">PDF Transcript</a>
            </p>
          </div>        
        </Layout>
      </div>
    )
  }
}